<script lang="ts" setup>
import { ref, computed } from 'vue'

import '@lahaus-roomie/roomie/src/components/RoomieNavigationButton/_index.scss'
import FiltersIcon from '@lahaus-roomie/roomie/src/assets/icons/filter.svg'
import IconFilter from '@lahaus-roomie/roomie/src/assets/icons/v2/filter.svg'
import CloseIcon from '@lahaus-roomie/roomie/src/assets/icons/close.svg'
import RoomieChipSelector from '@lahaus-roomie/roomie/src/components/RoomieChipSelector/index.vue'
import { storeToRefs } from 'pinia'
import type { OnboardingFilters } from '../types'
import type { Props, ChipFilter } from './types'
import SearchFiltersModalContent from '@/components/SearchFilters/ModalContent/index.vue'
import SearchModalFilters from '@/components/BrandedV2/FormSearchFiltersModal/index.vue'
import { useSearchFiltersStore } from '@/stores/searchFiltersStore'
import AppSlider from '@/components/AppSlider/index.vue'
import './_index.scss'

import { parseAreaFilterLabel, parsePriceFilterLabel } from '@/components/OnboardingContent/FiltersBar/utils'
import { useLocationsStore } from '@/stores/locationsStore'
import { useThemeStore } from '@/stores/useThemeStore'
import { unslugify } from '@/utils/strings'

const themeStore = useThemeStore()

withDefaults(defineProps<Props>(), {
  screen: 'onboarding'
})

const { t, locale } = useI18n()
const route = useRoute()

const isFiltersModalOpen = ref(false)

const locationsStore = useLocationsStore()
const searchFiltersStore = useSearchFiltersStore()
const { currentFilters: filters, isThereAnyLocationParam } = storeToRefs(searchFiltersStore)

const onModalFiltersChange = (newFilters: OnboardingFilters) => {
  searchFiltersStore.updateFilters(newFilters, route.path)
  isFiltersModalOpen.value = false
}

const removeChip = async (chip: ChipFilter) => {
  if (chip.filterName === 'businessHubs') {
    const newHubs = filters.value.businessHubs.filter(hubValue => hubValue !== chip.value)
    const neighborhoodsToDelete = locationsStore.locationsQueryUtils.getNeighborhoodsFromBusinessHubValue(chip.value).map(neighborhood => neighborhood.value)
    const newNeighborhoods = filters.value.neighborhoods.filter(neighborhoodValue => !neighborhoodsToDelete.includes(neighborhoodValue))

    await searchFiltersStore.updateFilters({
      ...filters.value,
      clusters: [],
      businessHubs: newHubs,
      neighborhoods: newNeighborhoods
    }, route.path)
  } else if (chip.filterName === 'zonesByBusinessHub') {
    const neighborhoodsToDelete = locationsStore.locationsQueryUtils.getNeighborhoodsFromBusinessHubValue(chip.value).map(neighborhood => neighborhood.value)
    const newNeighborhoods = filters.value.neighborhoods.filter(neighborhoodValue => !neighborhoodsToDelete.includes(neighborhoodValue))
    const newHubs = filters.value.businessHubs.filter(hubValue => hubValue !== chip.value)

    await searchFiltersStore.updateFilters({
      ...filters.value,
      clusters: [],
      businessHubs: newHubs,
      neighborhoods: newNeighborhoods
    }, route.path)
  } else if (chip.filterName === 'price') {
    await searchFiltersStore.updateFilters({
      ...filters.value,
      price: {
        min: null,
        max: null
      }
    }, route.path)
  } else if (chip.filterName === 'area') {
    await searchFiltersStore.updateFilters({
      ...filters.value,
      area: {
        min: null,
        max: null
      }
    }, route.path)
  } else if (chip.filterName === 'location') {
    await searchFiltersStore.updateFilters({
      ...filters.value,
      location: null
    }, route.path)
  } else {
    const newFilterValue = null

    await searchFiltersStore.updateFilters({
      ...filters.value,
      clusters: [],
      [chip.filterName]: newFilterValue
    }, route.path)
  }
}

const chips = computed(() => {
  const totalFilters: ChipFilter[] = []

  if (filters.value.neighborhoods?.length > 0) {
    const currentHubs = locationsStore.locationsQueryUtils.getBusinessHubsByNeighborhoodsValues(filters.value.neighborhoods)

    const newChips = currentHubs.map(
      (hub) => {
        const selectedZonesByHub = locationsStore.locationsQueryUtils.getBusinessHubZonesByNeighborhoodsValues(hub.value, filters.value.neighborhoods, locale.value)

        return ({
          filterName: 'zonesByBusinessHub',
          label: t('businessHubs', { businessHub: hub.alias || hub.name, count: selectedZonesByHub.length }, selectedZonesByHub.length),
          value: hub.value
        })
      }
    )

    totalFilters.push(...newChips)
  }

  if (filters.value.businessHubs?.length > 0) {
    const selectedHubs = locationsStore.locationsQueryUtils.getBusinessHubsByValues(filters.value.businessHubs)

    const newChips = selectedHubs.map(
      hub => ({
        filterName: 'businessHubs',
        label: hub.alias || hub.name,
        value: hub.value
      })
    )

    totalFilters.push(...newChips.filter(newChip => !totalFilters.some(chip => chip.value === newChip.value)))
  }

  if (filters.value.deliveryTerm) {
    totalFilters.push({
      filterName: 'deliveryTerm',
      label: t(`delivery_terms.${filters.value.deliveryTerm}`),
      value: filters.value.deliveryTerm
    })
  }

  if (filters.value.bedroomsNumber) {
    totalFilters.push({
      filterName: 'bedroomsNumber',
      label: isNaN(Number(filters.value.bedroomsNumber)) || filters.value.bedroomsNumber.toString().includes('+')
        ? t(`bedrooms_${filters.value.bedroomsNumber}`)
        : t('bedrooms_number', { count: Number(filters.value.bedroomsNumber) }, Number(filters.value.bedroomsNumber)),
      value: filters.value.bedroomsNumber
    })
  }

  if (filters.value.parkingLotsNumber) {
    totalFilters.push({
      filterName: 'parkingLotsNumber',
      label: isNaN(Number(filters.value.parkingLotsNumber))
        ? t(`parking_lots_${filters.value.parkingLotsNumber}`)
        : t('parking_lots_number', { count: Number(filters.value.parkingLotsNumber) }, Number(filters.value.parkingLotsNumber)),
      value: filters.value.parkingLotsNumber
    })
  }

  if (filters.value.bathroomsNumber) {
    totalFilters.push({
      filterName: 'bathroomsNumber',
      label: isNaN(Number(filters.value.bathroomsNumber)) || filters.value.bathroomsNumber.toString().includes('+')
        ? t(`bathrooms_${filters.value.bathroomsNumber}`)
        : t('bathrooms_number', { count: Number(filters.value.bathroomsNumber) }, Number(filters.value.bathroomsNumber)),
      value: filters.value.bathroomsNumber
    })
  }

  if (Object.values(filters.value.price).some(Boolean)) {
    totalFilters.push({
      filterName: 'price',
      label: parsePriceFilterLabel(filters.value.price.min, filters.value.price.max, { t, locale: locale.value }),
      value: filters.value.price
    })
  }

  if (Object.values(filters.value.area).some(Boolean)) {
    totalFilters.push({
      filterName: 'area',
      label: parseAreaFilterLabel(filters.value.area.min, filters.value.area.max, { t, locale: locale.value }),
      value: filters.value.area
    })
  }

  if (filters.value.q) {
    totalFilters.push({
      filterName: 'q',
      label: t('search', { q: filters.value.q }),
      value: filters.value.q
    })
  }

  if (filters.value.location) {
    if (filters.value.location.neighborhood) {
      totalFilters.push({
        filterName: 'location',
        label: t('location.neighborhood', { neighborhood: unslugify(filters.value.location.neighborhood) }),
        value: filters.value.location
      })
    } else if (filters.value.location.zone) {
      totalFilters.push({
        filterName: 'location',
        label: t('location.zone', { zone: unslugify(filters.value.location.zone) }),
        value: filters.value.location
      })
    } else if (filters.value.location.city) {
      totalFilters.push({
        filterName: 'location',
        label: t('location.city', { city: unslugify(filters.value.location.city) }),
        value: filters.value.location
      })
    }
  }

  return totalFilters
})
</script>

<script lang="ts">
export default {
  name: 'OnboardingFiltersBar'
}
</script>

<template>
  <div
    class="onboarding-filters-bar"
    :class="isFiltersModalOpen ? 'z-50' : 'z-20'">
    <LazyAppModal
      v-if="isFiltersModalOpen"
      position="fixed"
      :class="{
        'onboarding-filters-bar__search-filters-modal-branded': themeStore.isV2,
        'onboarding-filters-bar__search-filters-modal': !themeStore.isV2
      }"
      class="z-50"
      @close="isFiltersModalOpen = false">
      <SearchModalFilters
        v-if="themeStore.isV2"
        :screen-name="screen"
        class="h-full"
        @filters-change="onModalFiltersChange" />

      <SearchFiltersModalContent
        v-else
        :initial-filters="filters"
        :screen="`${screen}-filters-modal`"
        class="h-full"
        :enable-location-defaults="!isThereAnyLocationParam"
        enable-price-filter
        @filters-change="onModalFiltersChange" />
    </LazyAppModal>

    <div class="flex flex-col flex-1 overflow-hidden">
      <div
        v-if="chips.length"
        class="onboarding-filters-bar__chips-track"
        :class="{ 'mb-16': $slots.title }">
        <AppSlider
          :id="`${screen}-modal-filters-bar-chips`"
          class="w-full"
          padding-right
          padding-left="hidden">
          <template
            v-for="chip in chips"
            :key="chip.label">
            <RoomieChipSelector
              :id="`${screen}-filters-bar-item-${chip.filterName}`"
              :data-lh-id="`${screen}-filters-bar-item-${chip.filterName}`"
              icon-placement="right"
              :is-selected="false"
              class="onboarding-filters-bar__chip"
              @click="() => removeChip(chip)">
              <template #content>
                <span class="text-12 md:text-14 whitespace-nowrap pointer-events-none">
                  {{ chip.label }}
                </span>
              </template>

              <template #icon>
                <CloseIcon class="w-24 h-24 fill-current fill-primary-600 pointer-events-none" />
              </template>
            </RoomieChipSelector>
          </template>
        </AppSlider>
      </div>

      <div
        v-else-if="placeholder && !themeStore.isV2"
        class="pl-24 md:pl-0 my-auto lg:hidden">
        <p class="onboarding-filters-bar__placeholder-text text-brand-800 text-12 font-semibold">
          {{ placeholder }}
        </p>
      </div>

      <div
        v-if="$slots.title && !themeStore.isV2"
        class="onboarding-filters-bar__title flex-1">
        <slot name="title" />
      </div>

      <div
        v-if="themeStore.isV2 && !chips.length"
        class="onboarding-filters-bar__title flex-1 body-2-m ml-24 md:ml-0 md:hl-3-sb text-white">
        {{ t('editPreferencesTitle') }}
      </div>
    </div>

    <div class="onboarding-filters-bar__modal-preferences-trigger flex gap-8 items-center">
      <div
        v-if="themeStore.isV2"
        :id="`${screen}-filters-preferences-trigger`"
        class="body-1-m text-lime-600 flex items-center gap-10 cursor-pointer"
        :data-lh-id="`${screen}-filters-preferences-trigger`"
        @click="isFiltersModalOpen = true">
        <span class="hidden md:block pointer-events-none">
          Filtrar
        </span>

        <IconFilter class="w-24 h-24 pointer-events-none" />
      </div>

      <button
        v-else
        :id="`${screen}-filters-preferences-trigger`"
        :data-lh-id="`${screen}-filters-preferences-trigger`"
        class="roomie-button-navigation roomie-button-navigation--light px-10 lg:px-16 lg:pl-24 py-10 lg:py-12 rounded-circle lg:rounded-md border border-primary-600"
        :aria-label="t('filters_modal_trigger.aria_label')"
        @click="isFiltersModalOpen = true">
        <p class="text-14 text-primary-600 font-medium hidden lg:block mr-12">
          {{ t('editPreferences') }}
        </p>

        <FiltersIcon class="w-18 h-18 lg:w-24 lg:h-24 fill-current fill-primary-600 pointer-events-none" />
      </button>
    </div>
  </div>
</template>

<i18n src="./i18n.json" />
