<script lang="ts" setup>
import RoomieLoader from '@lahaus-roomie/roomie/src/components/RoomieLoader/index.vue'
import IconLogo from '@lahaus-roomie/roomie/src/assets/icons/v2/la-haus.svg'
import './_index.scss'

const { t } = useI18n()

</script>

<script lang="ts">
export default {
  name: 'OnboardingStepsLoader'
}
</script>

<template>
  <section class="onboarding-steps-loader">
    <div class="max-w-lg text-center">
      <IconLogo class="h-32 mx-auto mb-32 text-lime-600" />

      <div class="mt-10 mb-24 flex justify-center items-center text-white">
        <RoomieLoader
          :loading="true"
          size="xl" />
      </div>

      <h2 class="text-white text-20 font-semibold text-center">
        {{ t('title') }}
      </h2>
    </div>
  </section>
</template>

<i18n src="./i18n.json" lang="json" />
