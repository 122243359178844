import { slugify } from '@/utils/strings'
import { InvestmentProjectSummary, Typology } from '@/server/api/buyer-front/types'
import type { SearchFilters } from '@/components/SearchFilters/types'
import type { LocationsQueryUtils } from '@/stores/locationsStore/types'

export const typologyBedroomsFilter = (selectedValue: SearchFilters['bedroomsNumber'], typology: Typology) => {
  if (selectedValue === null) return true

  if (selectedValue === '4+') return (typology.bedroom || 0) >= 4

  return typology.bedroom.toString() === selectedValue.toString()
}

export const bedroomsFilter = (selectedValue: SearchFilters['bedroomsNumber'], project: InvestmentProjectSummary, opposite = false) => {
  if (!project.typologies) return false

  const matches = project.typologies.some(typology => typologyBedroomsFilter(selectedValue, typology))
  if (opposite) return !matches
  return matches
}

export const typologyPriceFilter = (selectedValue: SearchFilters['price'], typology: Typology) => {
  if (selectedValue.min === null && selectedValue.max === null) return true
  if (selectedValue.min === null && selectedValue.max !== null) return typology.price <= selectedValue.max
  if (selectedValue.max === null && selectedValue.min !== null) return typology.price >= selectedValue.min

  return typology.price >= selectedValue.min && typology.price <= selectedValue.max
}

export const typologyAreaFilter = (selectedValue: SearchFilters['area'], typology: Typology) => {
  if (selectedValue.min === null && selectedValue.max === null) return true
  if (selectedValue.min === null && selectedValue.max !== null) return typology.min_area <= selectedValue.max
  if (selectedValue.max === null && selectedValue.min !== null) return typology.min_area >= selectedValue.min

  return typology.min_area >= selectedValue.min && typology.min_area <= selectedValue.max
}

export const priceFilter = (selectedValue: SearchFilters['price'], project: InvestmentProjectSummary, opposite = false) => {
  if (!project.typologies) return false

  const matches = project.typologies.some(typology => typologyPriceFilter(selectedValue, typology))

  if (opposite) return !matches
  return matches
}

export const areaFilter = (selectedValue: { min: number; max: number }, project: InvestmentProjectSummary, opposite = false) => {
  if (!project.typologies) return false

  const matches = project.typologies.some(typology => typologyAreaFilter(selectedValue, typology))

  if (opposite) return !matches
  return matches
}

export const searchFilter = (selectedValue: string, project: InvestmentProjectSummary, opposite = false) => {
  const matches = project.name.toLowerCase().includes(selectedValue.toLowerCase()) ||
    project.description.toLowerCase().includes(selectedValue.toLowerCase()) ||
    project.neighborhood_label.toLowerCase().includes(selectedValue.toLowerCase()) ||
    project.zone_label.toLowerCase().includes(selectedValue.toLowerCase()) ||
    project.city_label.toLowerCase().includes(selectedValue.toLowerCase())

  if (opposite) return !matches
  return matches
}

export const idsFilter = (selectedValue: string[], project: InvestmentProjectSummary, opposite = false) => {
  const matches = selectedValue.includes(project.code)
  if (opposite) return !matches
  return matches
}

export const deliveryTermFilter = (selectedValue: string, project: InvestmentProjectSummary, opposite = false) => {
  const currentDate = new Date(Date.now())
  currentDate.setHours(0, 0, 0, 0)

  const projectDeliveryDate = new Date(project.delivered_at)

  const dateWithAddedMonths = (months: number): Date => {
    const date = new Date(currentDate)
    date.setMonth(date.getMonth() + months)
    return date
  }

  let matches: boolean

  switch (selectedValue) {
  case 'immediate':
    matches = projectDeliveryDate <= dateWithAddedMonths(3)
    break
  case '3-to-6-months':
    matches = projectDeliveryDate > dateWithAddedMonths(3) &&
        projectDeliveryDate <= dateWithAddedMonths(6)
    break
  case '6-to-12-months':
    matches = projectDeliveryDate > dateWithAddedMonths(6) &&
        projectDeliveryDate <= dateWithAddedMonths(12)
    break
  case '12-to-24-months':
    matches = projectDeliveryDate > dateWithAddedMonths(12) &&
        projectDeliveryDate <= dateWithAddedMonths(24)
    break
  case 'more-than-24-months':
    matches = projectDeliveryDate > dateWithAddedMonths(24)
    break
  case 'more-than-6-months':
    matches = projectDeliveryDate > dateWithAddedMonths(6)
    break
  }

  if (opposite) return !matches

  return matches
}

export const neighborhoodsFilter = (selectedValues: string[], project: InvestmentProjectSummary, opposite: boolean, locationsQueryUtils: LocationsQueryUtils) => {
  const businessHubsNames = locationsQueryUtils.getBusinessHubsByNeighborhoodsValues(selectedValues).map(hub => hub.name)
  const neighborhoodNames = locationsQueryUtils.getNeighborhoodsByValues(selectedValues).map(neighborhood => neighborhood.name)

  const matches = neighborhoodNames.includes(project.neighborhood_label) && businessHubsNames.includes(project.business_hub)

  if (opposite) return !matches
  return matches
}

export const citySlugFilter = (selectedValue: string, project: InvestmentProjectSummary, opposite = false) => {
  const matches = slugify(project.city_label) === selectedValue
  if (opposite) return !matches
  return matches
}

export const zoneSlugFilter = (selectedValue: string, project: InvestmentProjectSummary, opposite = false) => {
  const matches = slugify(project.zone_label) === selectedValue
  if (opposite) return !matches
  return matches
}

export const neighborhoodSlugFilter = (selectedValue: string, project: InvestmentProjectSummary, opposite = false) => {
  const matches = slugify(project.neighborhood_label) === selectedValue

  if (opposite) return !matches

  return matches
}

export const typologyParkingLotsFilter = (selectedValue: SearchFilters['parkingLotsNumber'], typology: Typology & { parking_numbers?: number }) => {
  if (selectedValue === null) return true

  if (selectedValue === 'No') return (typology.parking_numbers || 0) === 0

  return (typology.parking_numbers || 0).toString() === selectedValue.toString()
}

export const parkingLotsFilter = (selectedValue: string, project: InvestmentProjectSummary, opposite = false) => {
  if (!project.typologies) return false

  const matches = project.typologies.some(typology => typologyParkingLotsFilter(selectedValue, typology as Typology & { parking_numbers?: number }))

  if (opposite) return !matches

  return matches
}

export const typologyBathroomsFilter = (selectedValue: SearchFilters['bathroomsNumber'], typology: Typology) => {
  if (selectedValue === null) return true

  if (selectedValue === '4+') return (typology.bathroom || 0) >= 4

  return (typology.bathroom || 0).toString() === selectedValue.toString()
}

export const bathroomsFilter = (selectedValue: string, project: InvestmentProjectSummary, opposite = false) => {
  if (!project.typologies) return false

  const matches = project.typologies.some(typology => typologyBathroomsFilter(selectedValue, typology))

  if (opposite) return !matches

  return matches
}
