<script lang="ts" setup>
import type { Props } from './types'

defineProps<Props>()
</script>

<script lang="ts">
export default {
  name: 'AppEmptyState'
}
</script>

<template>
  <div class="app-empty-state text-center md:text-left p-32 mx-auto border border-carbon-light-300 rounded-xl flex flex-col md:items-center md:flex-row gap-14">
    <slot name="icon" />

    <div class="flex-1">
      <p
        v-if="title"
        class="mb-4 display-7 text-pine-600">
        {{ title }}
      </p>

      <p
        v-if="description"
        class="mb-16 md:mb-0 text-pine-600 body-1-m">
        {{ description }}
      </p>
    </div>

    <div>
      <slot />
    </div>
  </div>
</template>
